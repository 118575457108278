@font-face {
    font-family: 'VTF Justina';
    src: url(./../fonts/VTFJustinaGEO-Bold.otf) format('opentype');
}
body {
    font-family: 'VTF Justina', sans-serif;
  }
.container{
    margin: 0;
    padding: 0;
    overflow: auto
}
.topgap{
    background: #ed592b;
    font-size: 10px;

}
.mainContent{
    background: linear-gradient(rgba(19, 17, 72, 0.5), rgb(19, 17, 72)), url('./../../public/band.jpg');
    overflow: auto;
    background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.menulinks{
    width: 13%;
    float: left;
    overflow: auto;
    font-size: 1.5rem;
}
.menulinks1{
    float: left;
    overflow: auto;}
.leftLinks{
    width: 100%;
    float: left;
}
.topmargin{
    margin-top: 60px;
}
.Contact{
    width: 13%;
    float: left;
    text-align: center;
    font-size: 1.5rem;
}
.Donate{
    width: 20%;
    float: left;
    text-align: center;
  justify-content: center;
  display: flex;
  font-size: 1.5rem;
}
.linkingDonate{
    background: #ed592b;
    height: 37px;
    width: 110px;
  padding: 5px;
  color: #fff;
  outline: none;
  border-radius: 20px;
  text-decoration: none;
  line-height: 30px;
}
.Social{
    width: 33.33%;
    float: left;
}
.leftText{
    clear: both;
    float: left;
    width: 60%;
    margin-top: 50px;
}
.rightText{
    float: right;
    width: 40%;
    margin-top: 50px;
}
.mainText{
    font-size: 65px;
    color: #ffffff;
}
.links{
    text-decoration: none;
    color: #fff;
}
.logoimgs{
    width: 320px;
  margin-top: 35px;
}
.heroimages{
    width: 65%;
  margin-top: 50px;
}
.hamburger{
    display: none;
}
.overlay-content{
    display: none;
}

.closebtn{
    display: none;
}

@media (max-width: 768px) {
    .hamburger{display: block !important; margin-top: 50px;}
    .menulinks, .Donate, .Contact{
        display: none !important;
    }
    .overlay {
        height: 0%;
        width: 100%;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background: rgba(19, 16, 71, 0.9);
        background: rgba(19, 16, 71, 0.9);
        overflow-y: hidden;
        transition: 0.5s;
      }
      
      .overlay-content {
        position: relative;
        top: 25%;
        width: 100%;
        text-align: center;
        margin-top: 30px;
        display: block !important;
      }
      
      .overlay a {
        padding: 8px;
        text-decoration: none;
        color: #fff;
        font-size: 2.2rem;
        display: block;
        transition: 0.3s;
      }
      
      .overlay a:hover, .overlay a:focus {
        color: #f1f1f1;
      }
      
      .overlay .closebtn {
        position: absolute;
        top: 20px;
        right: 45px;
        font-size: 60px;
      }

      .closebtn{
        display: block;
      }

        .overlay {overflow-y: auto;}
        .overlay a {color: #fff; font-size: 2.2rem;}
        .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 35px;
        }

        .leftText{
            width: 100% !important;
            margin-top: 0px !important;
        }
        .mainText{
            font-size: 2.5rem !important;
            line-height: 2.5rem !important;
            margin: 0px;
            margin-top: 50px;
        }
        .rightText{
            width: 100% !important;
        }
        


}