*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.wrapper{
    max-width: 90%;
    margin: 0 auto;
}
.wrapper h1{
    padding: 30px 0px;
    text-align: center;
    text-transform: uppercase;
}
.project{
    display: flex;
}
.shop{
    flex: 75%;
    color: white;
}
.box{
    display: flex;
    width: 100%;
    height:auto;
    overflow: hidden;
    margin-bottom: 20px;
    
    transition: all .6s ease;
    box-shadow: rgba(100, 100, 11, 0.2) 0 7px 29px 0;
}
.box:hover{
    transform: scale(1.02);
}
.box img{
    width: 600px;
    height: 600px;
    object-fit: cover;
}
.content h3{
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: left;
}
.content h4{
    margin-bottom: 50px;
    text-align: left;
}
.btn-area{
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 10px 25px;
    background-color: #3a71a9;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 600;
}
.btn-area:hover{
    background-color: #76bfb6;
    color: #fff;
}
.unit input{
    width: 50px;
    padding: 8px;
    text-align: center;
}
.unit2 input{
    width: 50%;
    background: #3fd168;
    padding: 8px;
    text-align: center;
    border-radius: 20px;
    border: none;
    outline: navajowhite;
    cursor: pointer
}

.itemdetailrow{ width: 100%;}
.size {
    color: #8e9aac;
    font-size: 18pt;
    border: 1px solid #8e9aac;
    border-radius: 50px;
    width: 50px;
    display: inline-block;
    text-align: center;
    height: 50px;
    vertical-align: bottom;
    line-height: 50px;
  }
  .sizeprice {
    color: #8e9aac;
    font-size: 10pt;
  }
  .selectcls{height: 40px;
    width: 200px;
    font-size: 16px;
    text-indent: 2px;}
    .itemname1{font-family: 'VTF Justina', sans-serif;}

    .pthumb{ background:white; height: 600px; display: flex;
        justify-content: center;
        align-items: center;}
    .thimg{width: 500px; height:auto; max-height: 550px; }

    .content{ margin-left: 10%;}

    .sizeprice2{font-size: 14px;}

    @media (max-width: 768px) {
        .content{ margin-left: 0% !important;}
        .box{display: block !important;}
        .pthumb{  height: 500px;}
        .thimg{width: 350px !important;  }
       

        .size {
            color: #8e9aac;
            font-size: 10pt;
            border: 1px solid #8e9aac;
            border-radius: 50px;
            width: 30px;
            display: inline-block;
            text-align: center;
            height: 30px;
            vertical-align: bottom;
            line-height: 30px;
          }

          .sizeprice {
           
            font-size: 8pt;
          }

          .sizeprice2{font-size: 12px;}
    }