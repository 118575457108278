body{
    margin: 0;
    padding: 0;
}
.checkmain{ width: 100%; display:block; margin: 0 auto; border-radius: 10px;background: #131047; max-width: 1000px; }
.checkoutstripcart{color: #fff; font-size: 55px; border-top: 2px solid #ed592b; margin-top: 100px;}
.checkoutdetailcart{ width: 100%; color: black; display:flex; margin-top: 10px; align-items: center; }
.checkitem{width: 25%;line-height: 70px;
    font-size: 25px;
  }
  .checkitem2{width: 25%;line-height: 40px;
    font-size: 25px;
  }

  .checkoutitem{width: 25%;line-height: 70px;
    font-size: 20px;
  }

.itemImage2{width: 50%;
  height: 100%;
  object-fit: contain;
  max-height: 104px;
  min-height: 104px;
  background: #f1f1f1;
  border-radius: 92px;}

    hr {
      color: #3b3b3b;
      overflow: visible;
      height: 2px;
      width: 95%;
      text-align: center;
      margin: 0 auto;
      }
      
      hr::after {
        background: #fff;
        padding: 0 4px;
        position: relative;
        top: -13px;
      }

      .cancle{
        background: none;
  border: none;
  outline: none;
  color: red;
  cursor: pointer;
  float: left;
  width: 20%;
  margin-left: 50px;
      }
      .edit{
        background: none;
        border: none;
        outline: none;
  color: #4747e8;
  cursor: pointer;
  float: left;
  width: 10%;
      }
      .checkout{cursor: pointer;
        border: none;
        font-size: 30px;}

        .shipMain{
          width: 100%;
  float: left;
  background: #131047;
  color: white;
        }

        .actionlinks{
          width: 50%;
          height: 20px;
        }

        .shiptotal{
          width: 40%;
          float: right;
        }


        @media (max-width: 768px) {
          .itemImage2{
            height: 50% !important;
            max-height: 50px;
            min-height: 50px;
            border-radius: 100px;
            width: 60%;
          }
          .actionlinks{
            width: 100%;
          }
          .edit{
            width: 25%;
          }
          
          .shiptotal{
            width: 95%;
    margin: 0 auto;
    float: none;
          }
        }