body{
    margin: 0;
    padding: 0;
}
.mainShopContainer{max-width: 90%;  
  margin-bottom: 20px; margin: 0 auto; padding-top: 20px; display: grid;
  grid-template-columns: repeat(3, 1fr);}
.Blocks4{ 
 
  background: #fff;
  padding: 1em;
  border-radius: 22px;
 
  transition: 1s;
  margin:5%;
  border: 2px solid #eaebec;
}
.Blocks4 img{
  max-width: 100%;
  max-height: 340px;
}
.itemPrice p{
  
  margin: 0.5em 0;

  color: #ed592b;
    font-family: 'Akrobat', serif;
    font-weight: 700;  font-size: 2.0rem

}
.itemImage{
  max-width: 100%;
}
   .itemname{ width: 100%; height: 50px;line-height: 50px;
    text-align:center; color: #131047;
    font-family: 'Akrobat', serif;
    font-weight: 700;  font-size: 2.0rem}

    .spanitem{padding-left: 10px;}

   .footerstrip {
    display: flex; /* Enable flexbox */
    justify-content: space-between; /* Space out the items to the left and right */
    align-items: center; /* Align items vertically in the center */
    height: 50px;
    margin-top: 30px;
  }
  
  .itemPrice {
    flex: 1; /* Takes available space */
    text-align: center; /* Align text to the left */
  }
  
  .shopBtn {
    flex: 0; /* Prevents it from growing */
    text-align: right; /* Align button to the right */
  }
  
  .shopBtn input {
    background-color: #000;
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 1em;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .Blocks4:hover {
    background-color: lightslategray;
    color: #fff;
    transition: 1s;
  }
  .Blocks4:hover .itemname{
    color: #fff;
    transition: 0.4s;
  }
  .Blocks4:hover .shopBtn input{
    color: #000;
    background-color: #fff;
    transition: 0.4s;
  } 

  .shopBtn input:hover {
    background-color: #0056b3; /* Hover effect */
    transition: 0.3s;
  } 

  .productrows{ width: 100%;   margin-bottom: 100px;}

  .productimgblock{min-height: 350px;}

  @media (max-width: 768px) {
  .mainShopContainer {display: block;}
  .Blocks4 { max-width: 100%;}

  }