.imgmee{width: 10%;
    margin-top: 50px;
    text-align: left;
    justify-content: left;
    display: grid;
    margin-left: 12%;}
.post{
    color: #ffffff;
    width: 18%;
    text-align: center;
    font-size:20px;
    margin-top: 45px;
    margin-left: 160px;
}
.leftabout{
width: 50%;
float: left;
margin-top: 0px;
}

.post2{
    color: #ffffff;
    width: 100%;
    text-align: center;
    font-size:20px;
    margin-top: 45px;
}

.imgmee2{
    width: 20%;
    border-radius: 100%;
}
    @media (max-width: 768px) {
        .imgmee{width: 35%; margin: 0 auto;}
        .post{text-align: center !important;
            margin: 0 auto !important;}

        .leftabout{
            width: 100% !important;
            float: left;
            margin-top: 50px !important;
            }

        .imgmee2{width: 40% !important;}    

    }