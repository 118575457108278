body{
    margin: 0;
    padding: 0;
}
.checkmain{ width: 100%; max-width: 1000px; display:block; margin: 0 auto; }
.checkoutstrip{ width: 100%; color: black; color: white;}
.checkoutdetail{ width: 100%; color: black; display:flex; color: white;}
.checkitem{width: 25%;}
.itemImage2{ width: 50%;
    height: 100%; object-fit: contain; max-height: 150px; min-height: 150px;}

    .checkoutleft{ width: 40%; color: white; }

    .checkoutright{ width: 60%; color: white;}

    .checkoutinner{width: 100%; display: flex;}
 .shippingdetails{ text-align: left; padding-left: 10px;}  
 .fandlname{ text-align: left; padding-left: 10px;} 
 .inputbox{ width: 300px; height: 40px; margin-top: 10px; font-size: 14px; text-indent: 10px;}

 .fandlname{ margin-left: 40px;}
 .checkitem3{width: 25%;
   
    font-size: 25px;}
    .itemImage3 {
        width: 50%;
        height: 42%;
        object-fit: contain;
        max-height: 104px;
        min-height: 65px;
        background: #f1f1f1;
        border-radius: 92px;
      }

      .cancle1{
        background: none;
  border: none;
  outline: none;
  color: red;
  cursor: pointer;
  float: left;
  width: 20%;
  margin-left: 50px;
  margin-top: -15px;
      }
      .edit1{
        background: none;
        border: none;
        outline: none;
  color: #4747e8;
  cursor: pointer;
  float: left;
  width: 10%;
  margin-top: -15px;
      }
      .actions{
        width: 50%;
        height: 20px;
      }
.shipmain2{
  width: 60%;
  float: right;
}
      @media (max-width: 768px) {

        .checkoutinner{
          display: block;
        }
        .checkoutright{
          width: 95%;
          margin: 0 auto;
        }

        .actions{
          width: 100%;
          height: 20px;
        }
        .edit1{
          width: 25%;
        }
        .itemImage3{
          width: 80%;
    height: 50%;
    max-height: 61px;
    min-height: 61px;
        }
        .shipmain2{
          width: 95%;
    margin: 0 auto;
    float: none;
        }

      }
