@font-face {
  font-family: 'Veneer';
  src: url(./../fonts/Fontspring-DEMO-Veneer.otf) format('opentype');
}
@import url('https://fonts.cdnfonts.com/css/akrobat');
body{
    margin: 0;
    padding: 0px;
    background: linear-gradient(rgba(19, 17, 72, 0.5), rgb(19, 17, 72)), url('./../../public/band.jpg');
    background-size: cover;
  background-repeat: no-repeat;
}
header{
    overflow: auto;
}
.left{
    width: 50%;
    float: left;
}
.left>h1{
  font-family: 'Veneer', sans-serif;
  font-size: 7rem;
  line-height: 95%;
  max-width: 42rem;
  font-weight: 400;
  margin: 0 auto 3.5rem auto;
  background: linear-gradient(151deg, #ffffff 7.23%, rgba(255, 255, 255, 0.4) 59.58%);
    background-clip: border-box;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nextText{font-family: 'Veneer', sans-serif;
  font-size: 3.3rem;
  line-height: 110%;
  font-weight: 400;
  margin: 0 auto 1rem auto;
  max-width: 48rem;
  color: #fff;}
.left>p{
    width: 85%;
  margin: 0 auto;
  text-align: left;
  color: #FFFFFF;
  font-size: 16px;
}
.left>.largetext{
  font-family: 'Veneer', sans-serif;
    width: 85%;
  margin: 0 auto;
  text-align: left;
  color: #FFFFFF;
  font-size: 24px !important;
}
.left>.largesttext{
    font-size: 32px!important;
}
.right{
    width: 50%;
    float: right;
}
.makeone{
    color: #FFFFFF;
    font-size: 34px;
    margin-top: 12%;
}
.donateBox{
  font-family: 'Akrobat', sans-serif;
    width: 40%;
  color: #333333;
  float: left;
  background: #FFFFFF;
  border-radius: 25px;
  height: 57px;
  line-height: 55px;
  font-size: 2rem;
  font-weight: bold;
}
.donateBox2{
  font-family: 'Akrobat', sans-serif;
    width: 40%;
  color: #333333;
  float: right;
  background: #FFFFFF;
  border-radius: 25px;
  height: 57px;
  line-height: 55px;
  font-size: 2rem;
  font-weight: bold;
}
.recurringDonateBox{
  font-family: 'Akrobat', sans-serif;
    width: 100%;
  color: #333333;
  float: left;
  background: #FFFFFF;
  border-radius: 25px;
  height: 57px;
  line-height: 55px;
  font-size: 2rem;
  font-weight: bold;
}
.donateBtn{
    width: 300px;
  height: 45px;
  border-radius: 25px;
  border: none;
  background: #ed592b;
  font-size: 24px;
  color: #FFFFFF;
  cursor: pointer;
  font-weight: bold;
}
.filler{
  font-family: 'Akrobat', sans-serif;
  max-width: 50rem;
  font-size: 2rem !important;
  line-height: 130%;
  font-weight: 500;
  color: #fff;
  margin: 0 auto 5rem auto;
}
input[type="radio"]{
  width: 20px;
  height: 25px;
}

@media (max-width: 768px) {
  .right{width: 100% !important;}
  .left{width: 100% !important;}
}